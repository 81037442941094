import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from 'react';

import axios from 'axios';

function App() {

  const [userName, setUserName] = useState('');
  const [newOrExistingUser, setNewOrExistingUser] = useState('');
  const [userAchievements, setUserAchievements] = useState([]);
  
  useEffect(() => {
    /* global google */

    const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    // console.info("Google Client ID:", client_id);
    
    google.accounts.id.initialize({
      client_id: client_id,
      callback: signInCallback,
      cancel_on_tap_outside: false,
    });

    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      size: "large",
    });

  }, []);

  const signInCallback = (result) => {
    // console.log("Result:", result);

    if (result.credential) {
      const params = { token: result.credential };
      axios
      // .post("http://localhost:3000/user/google/", params)
      .post("https://backend-accounts.roc1911.org/google/auth", params)
      .then((response) => {
        console.log("response:", response);

        const user_name = response.data.user_name;
        setUserName(user_name);

        const new_or_existing_user = response.data.new_or_existing_user;
        setNewOrExistingUser(new_or_existing_user);

        const user_achivements = response.data.user_achivements;
        setUserAchievements(user_achivements);



        // const { token, ...userInfo } = response.data.data;
        // set token in local storage/cookies
        // redirect to authenticated page
      })
      .catch((err) => console.log(err));

    }
  };

  
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}

        <h1>民的国1911 — 用户帐号</h1>
        {/* <h1>ROC 1911 - User Account</h1> */}

        <p>“民的国1911”是一个主要面向华夏民众的，独立于中共国之外的生存空间，从网络虚拟社区开始发展。</p>
        <p>对“民的国1911”来说，因为与中共的各种冲突，是不大可能避免的，所以“民的国1911”不允许有明显支持中共的用户存在。</p>
        <p>请各位在注册用户前慎重考虑。</p>
        
      </header>

      <main className="App-main">

        <div id="signInDiv"></div>

        {userName && <p>尊敬的{userName}，欢迎来到民的国。</p>}

        {/* {<p>测试提示文本：{newOrExistingUser}</p>} */}
        
        {newOrExistingUser === 'new' && <p>您的“民的国1911”用户帐号已注册完成。</p>}
        {newOrExistingUser === 'existing' && <p>您之前已经注册过“民的国1911”用户帐号，欢迎回来。</p>}

        {userAchievements.length > 0 && (
          <div>
            <p>您已获得以下成就：</p>
            <ul>
              {userAchievements.map((achievement, index) => (
                <li key={index}>{achievement}</li>
              ))}
            </ul>
          </div>
        )}

        {/* <p>测试文字</p> */}
        {/* <button onClick={testBackend}>Test Backend</button> */}
        
      </main>
      
      <footer className="App-footer">

        <p>安全建议：</p>
        <p>中国大陆居民加入本社区前，请做好身份隔离，用常用 Google帐号 以外的帐号注册本社区。</p>
        <p>中国大陆以外，中共难以直接控制的国度的居民，请自行充分权衡，做适当处理。</p>
        <p>决不能轻视中共的狡猾和残忍。</p>
        <p>参考：<a className="App-footer-link" href="https://groups.google.com/g/capital1911/c/8lxBlq7kH60">“江滩 — 叛都1911 论坛” 规则</a></p>
        
        <p>
          <a className="App-footer-link" href="https://walker1911.me">行者1911</a>&nbsp; @ &nbsp;
          <a className="App-footer-link" href="https://capital1911.org">叛都1911</a>&nbsp; in &nbsp;
          <a className="App-footer-link" href="https://roc1911.org">民的国1911</a>
        </p>

        <p>
          © 2024 民的国1911 — ROC 1911. All rights reserved.
        </p>
        
      </footer>
      
    </div>
  );
}

const testBackend = () => {
  console.info("Test passed");

  const params = {
    token: "test 123"
  };
  
  axios.post("https://backend-accounts.roc1911.org/google/auth", params)
    .then((response) => {
      console.info("response:", response);
    })
    .catch((err) => console.info(err));
}


export default App;
